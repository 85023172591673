import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {useForm } from 'react-hook-form'

const CardFormGroup = styled.div`
    padding: 10px;
    border: 1px solid ${({ theme }) =>  theme.colors.bingoBorderAccent};
    border-radius: 8px;
    & ~ div {
        margin-top: 10px;
    }
`

const AddSubButton = styled.button`
    padding: 8px 16px;
    margin: 20px 0 0 0;
    border-radius: 6px;
    color: ${({ theme }) =>  theme.colors.text};
    border: 1px solid ${({ theme }) =>  theme.colors.bingoBorder};
    cursor: pointer;
    & ~ button {
        margin: 10px 0 0 10px;
    }
    &:hover {
        background: ${({ theme }) =>  theme.colors.bingoBorderAccent};
    }
`

const ResetButton = styled.button`
    padding: 8px 16px;
    border-radius: 6px;
    color: ${({ theme }) =>  theme.colors.buttonColor};
    background: ${({ theme }) =>  theme.colors.danger};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) =>  theme.colors.dangerHover};
    }
`
const SubmitButton = styled.button`
    padding: 8px 16px;
    margin: 0 0 0 10px;
    border-radius: 6px;
    color: ${({ theme }) =>  theme.colors.buttonColor};
    background: ${({ theme }) =>  theme.colors.primary};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) =>  theme.colors.primaryAccent};
    }
`

const TitleLabel = styled.label`
    font-size: 12px;
    color: ${({ theme }) =>  theme.colors.text};
`

const TitleInput = styled.input`
    display: block;
    margin: 0 0 10px 0;
    padding: 8px;
    color: ${({ theme }) =>  theme.colors.text};
    border: 1px solid ${({ theme }) =>  theme.colors.bingoBorder};
`

const NumberTitle = styled.p`
    font-size: 12px;
    color: ${({ theme }) =>  theme.colors.text};
`

const NumberInput = styled.input`
    display: block;
    margin: 5px 0;
    padding: 8px;
    color: ${({ theme }) =>  theme.colors.text};
    border: 1px solid ${({ theme, error }) => error ? theme.colors.danger : theme.colors.bingoBorder};
` 

const Errors = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.danger};
`

export const CardsForm = ({ setCards, defaultValues }) => {
    const { handleSubmit, register, errors } = useForm({ defaultValues: defaultValues })
    const [numberOfRows, setNumberOfRows] = useState(1)

    useEffect(() => {
        setNumberOfRows(Object.keys(defaultValues).length / 4)
    }, [defaultValues])

    const onSubmit = (data) => {
        let cardsArray = []
        for(let i = 0; i <= numberOfRows - 1; i++) {
            let card = []
            for(let j = 0; j <= 2; j++) {
                const cleanedRow = cleanRow(data[`row-${i}-${j}`])
                card.push(sortTrimRow(cleanedRow))
            }
            cardsArray.push({
                title: data[`title-${i}`],
                rows: card
            })
        }
        setCards(cardsArray)
    }

    const addRow = () => {
        setNumberOfRows(numberOfRows + 1)
    }

    const removeRow = () => {
        if (numberOfRows > 1) {
            setNumberOfRows(numberOfRows - 1)
        }
    }

    const cleanRow = (row) => [... new Set(row.split(',').filter(number => typeof number && number !== NaN && number <= 90 && number > 0))]

    const sortTrimRow = (row) => {
        const sortedArray = row.sort((a, b) => a - b)
        let trimmedArray = []
        sortedArray.forEach((number, i) => {
            const low = Math.floor(number / 10)
            if(i === sortedArray.length - 1) {
                trimmedArray.push(parseInt(number))
            }
            const high = Math.floor(sortedArray[i + 1] / 10)
            if (low < high) trimmedArray.push(parseInt(number))
        })
        return trimmedArray
    }
    
    const validateRow = (row) => {
        const rowCleaned = cleanRow(row)
        const sortedTrimmedRow = sortTrimRow(rowCleaned)
        return sortedTrimmedRow.length === 5  
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            {[...Array(numberOfRows)].map((row, i) => <CardFormGroup key={`bingrow-${i}`}>
                <TitleLabel htmlFor={`title-${i}`}>Title</TitleLabel>
                <TitleInput name={`title-${i}`} ref={register} type="text" placeholder="title" />
                <NumberTitle>Numbers</NumberTitle>
                <NumberInput name={`row-${i}-0`} error={errors[`row-${i}-0`]?.type} className={errors[`row-${i}-0`] && 'error'} ref={register({validate: validateRow})} type="text" placeholder="1,15,27,49,78"/>
                <NumberInput name={`row-${i}-1`} error={errors[`row-${i}-0`]?.type} className={errors[`row-${i}-0`] && 'error'} ref={register({validate: validateRow})} type="text" placeholder="1,15,27,49,78"/>
                <NumberInput name={`row-${i}-2`} error={errors[`row-${i}-0`]?.type} className={errors[`row-${i}-0`] && 'error'} ref={register({validate: validateRow})} type="text" placeholder="1,15,27,49,78"/>
            </CardFormGroup>)}

            {!!Object.keys(errors).length && <Errors>All number fields must be 5 integers separated by commas ","</Errors>}
            <AddSubButton type="button" onClick={addRow}>Add Row</AddSubButton>
            <AddSubButton type="button" onClick={removeRow}>Remove Row</AddSubButton>
            <div>
                <ResetButton type="button" onClick={() => setCards([])}>Reset Cards</ResetButton>
                <SubmitButton type="submit">Submit cards</SubmitButton>
            </div>
        </form>
    </>
}

import React from 'react'
import styled from 'styled-components'

const NumberField = styled.div`
    background: ${({ isSelected, theme }) => isSelected ? theme.colors.primary : theme.colors.bingoBackground};
    color: ${({ theme }) => theme.colors.text};
`

const CardContainer = styled.div`
    display: inline-block;
    padding: 0 1px 1px;
    background: ${({ theme }) => theme.colors.bingoBorder};
    & + div {
        margin: 10px 0;
    }
		margin-bottom: 1.5rem;
`

const CardRow = styled.div`
    display: grid;
    grid-template-columns: repeat(9, 40px);
    text-align: center;
		width: min-content;
    line-height: 40px;
    grid-gap: 1px;
    margin-top: 1px;
`

const Row = ({ numbers, selectedNumbers }) => {
    const row = Array(9).fill(null)
    numbers.forEach(number => {
        if(number <= 10) {
            row[0] = number
        }
        if(number > 10 && number < 20) {
            row[1] = number
        }
        if(number >= 20 && number < 30) {
            row[2] = number
        }
        if(number >= 30 && number < 40) {
            row[3] = number
        }
        if(number >= 40 && number < 50) {
            row[4] = number
        }
        if(number >= 50 && number < 60) {
            row[5] = number
        }
        if(number >= 60 && number < 70) {
            row[6] = number
        }
        if(number >= 70 && number < 80) {
            row[7] = number
        }
        if(number >= 80) {
            row[8] = number
        }
    });

    return <CardRow>
        {row.map((number, i) => (
            <NumberField
                key={`CardRow-${i}`}
                hasNumber={number !== null}
                isSelected={selectedNumbers.includes(number)}
            >
                {number}
            </NumberField>
        ))}
    </CardRow>
}

const CardTitle = styled.p`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    font-style: italic;
`

export const Card = ({ card, selectedNumbers }) => <>
    <CardTitle>{card?.title}</CardTitle>
    <CardContainer>
        {card?.rows?.map((row, i) => <Row key={`row-${i}`} numbers={row} selectedNumbers={selectedNumbers}/>)}
    </CardContainer>
</>

export default [
    {
        title: '17914',
        rows: [
            [1,17,51,74,90],
            [21,30,46,53,61],
            [4, 18, 36, 47, 66]
        ]
    },
    {
        title: '17915',
        rows: [
            [33,42,56,63,86],
            [6,11,34,68,72],
            [19,26,38,48,75]
        ]
    },
    {
        title: '17916',
        rows: [
            [25,40,57,69,76],
            [7,12,32,77,82],
            [13,39,45,78,83]
        ]
    },
    {
        title: '17917',
        rows: [
            [2,22,30,50,60],
            [3,28,61,74,88],
            [4,15,45,52,62]
        ]
    },
    {
        title: '17918',
        rows: [
            [7,11,40,63,71],
            [29,37,55,76,81],
            [19,47,67,79,90]
        ]
    },
    {
        title: '17919',
        rows: [
            [12,24,32,46,54],
            [6,18,34,77,89],
            [27,35,48,59,68]
        ]
    },
]
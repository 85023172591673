import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin: 4px 10px;
`

const TopBarContainer = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 1024px;
		margin: 2.5rem auto 0;
`

export const TopBar = ({ handleChangeGameType, numberOfRowsSingleCard, numberOfRowsTotal}) => {
    return <TopBarContainer>
    <div>
        <Text>Change number of rows for bingo
        <select onChange={handleChangeGameType} style={{marginLeft: '10px'}}>
            <option value="0">One row</option>
            <option value="1">2 rows on same card</option>
            <option value="2">2 rows any card</option>
            <option value="3">Full card</option>
            <option value="4">3 rows any card</option>
        </select>
        </Text>
    </div>
    <Text>Number of completed rows on a single card: {numberOfRowsSingleCard}</Text>
    <Text>Number of completed rows in total: {numberOfRowsTotal}</Text>
    </TopBarContainer>
}
export const lightTheme = {
    theme: 'light',
    colors: {
        text: '#000',
        background: '#d4cfcf',
        textHover: '#403c3c',
        primary: '#1a54cf',
        primaryAccent: '#437aee',
        buttonColor: '#fff',
        bingoBackground: '#fff',
        bingoBackgroundHover: '#dcdcdc',
        bingoBorder: '#000',
        bingoBorderAccent: '#d6d6d6',
        bingoSelectedText: '#fff',
        danger: '#e82017',
        dangerHover: '#b9130b'
    },
    fonts: ['sans-serif', 'Montserrat', 'Hind'],
    TogglePin: {
        left: '25px',
    }
}

export const darkTheme = {
    theme: 'dark',
    colors: {
        text: '#fff',
        textHover: '#d4cfcf',
        background: '#403c3c',
        primary: '#c4c427',
        primaryAccent: '#eeee53',
        buttonColor: '#000',
        bingoBackground: '#1d1d1d',
        bingoBackgroundHover: '#2d2b2b',
        bingoBorder: '#fff',
        bingoBorderAccent: '#767676',
        bingoSelectedText: '#fff',
        danger: '#e82017',
        dangerHover: '#b9130b'
    },
    fonts: ['sans-serif', 'Montserrat', 'Hind'],
    TogglePin: {
        left: '0px',
    }
}

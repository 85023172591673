import React from 'react'
import styled from 'styled-components'

export const BingoLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0 60px 0;
    margin-top: 20px;
		max-width: 1024px;
		margin: 2.5rem auto;

		@media screen and (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
`

export const BingoAnnouncer = styled.div`
    padding: 20px 45px; 
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    background: rgb(30, 180, 80);
    font-weight: 900;
    box-shadow: 0px 4px 6px 0px #403f3f40;
`
import React from 'react'
import styled from 'styled-components'

const Main = styled.main`
	background-color: #2C3333;
	color: #E7F6F2;
	display: flex;
	font-size: 12vw;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
`

const Home = () => <Main>JRK</Main>

export default Home

import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import { ThemeProvider } from 'styled-components'
import { Home, Bingo } from './pages'
import { lightTheme } from './style/theme'

function App() {
  const [theme, setTheme] = useState(lightTheme)
  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/*<PageHeader setTheme={setTheme}/>*/}
				<Routes>
          <Route exact path='/' element={<Home />} />
					<Route exact path='/bingo' element={<Bingo />} />
          <Route path='*' element={<Home />} />
				</Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App

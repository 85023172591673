import React, { useEffect, useState } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { Card } from './Bingo/Elements/Card'
import { Tabs } from './Bingo/Elements/NumberCaller'
import { BingoLayout, BingoAnnouncer } from './Bingo/Elements/BingoLayout'
import { TopBar } from './Bingo/Elements/TopBar'
import spc from './spc'

export const Bingo = () => {
    const [gameType, setGameType] = useState(0) 
    const [selectedNumbers, setSelectedNumbers] = useLocalStorage('bingoNumbers', [])
    const [cards, setCards] = useLocalStorage('bingoCards', [])
    const numbers = Array.from(Array(90), (_,x) => x  + 1);
    
    const [numberOfRowsSingleCard, setNumberOfRowsSingleCard] = useState(0)
    const [numberOfRowsTotal, setNumberOfRowsTotal] = useState(0)
    const [hasWin, setHasWin] = useState(false)
    const [annoucementShown, setAnnoucementShown] = useState(false)

    useEffect(() => {
        if ( window.location.href.split('/').slice(-1)[0] === 'ks') {
            setCards(spc)
        }
    },[])

    const handleCardsForm = data => setCards(data)
    const resetGame = () => setSelectedNumbers([])
    const handleClick = (number) => {
        let arr = [...selectedNumbers]
        if(selectedNumbers.includes(number)) {
            arr = arr.filter(n => n !== number)
        } else {
            arr.push(number)
        }
        setSelectedNumbers(arr)
    }

    const handleChangeGameType = e => setGameType(parseInt(e.target.value))

    const checkWin = (maxRowsOnSameCard, totalRowsOnAllCards) => {
        if (gameType === 0 && totalRowsOnAllCards >= 1) {
            return true
        }
        if (gameType === 1 && maxRowsOnSameCard >= 2) {
            return true
        }
        if (gameType === 2 && totalRowsOnAllCards >= 2) {
            return true
        }
        if (gameType === 3 && maxRowsOnSameCard === 3) {
            return true
        }
        if (gameType === 4 && totalRowsOnAllCards >= 3) {
            return true
        }
        return false
    }

    const triggerWin = () => {
        setHasWin(true)
        setAnnoucementShown(true)
    }

    useEffect(() => {
        if (!cards.length) return
        
        let totalRowsOnAllCards = 0
        let maxRowsOnSameCardArr = []
        const isInSelection = (currentValue) => selectedNumbers.includes(currentValue)
        const cardsLength = cards.length

        for (let i = 0; i < cardsLength; i++) {
            const rowsLength = cards[i].rows.length
            let maxRowsOnSameCard = 0
            for (let j = 0; j < rowsLength; j++ ){
                const rowHasAllNumbers = cards[i].rows[j]?.every(isInSelection)
                if (rowHasAllNumbers) {
                    ++maxRowsOnSameCard
                    ++totalRowsOnAllCards
                }
            }
            maxRowsOnSameCardArr.push(maxRowsOnSameCard)
        }
        setNumberOfRowsTotal(totalRowsOnAllCards)   
        setNumberOfRowsSingleCard(Math.max(...maxRowsOnSameCardArr))
        const hasWon = checkWin(Math.max(...maxRowsOnSameCardArr), totalRowsOnAllCards)
        if(hasWon) {
            setHasWin(true)
            setAnnoucementShown(true)
        } else {
            setHasWin(false)
            setAnnoucementShown(false)
        }
    }, [selectedNumbers, hasWin, gameType])

    return (
        <div style={{marginTop: '20px'}}>
            <TopBar
                handleChangeGameType={handleChangeGameType}
                numberOfRowsSingleCard={numberOfRowsSingleCard}
                numberOfRowsTotal={numberOfRowsTotal}
            />
            <BingoLayout>
                <div style={{padding: '0 10px'}}>
                    <Tabs
                        handleClick={handleClick}
                        numbers={numbers}
                        selectedNumbers={selectedNumbers}
                        cards={cards}
                        setCards={handleCardsForm}
                        resetGame={resetGame}
                    />
                </div>
                <div style={{margin: '1rem auto'}}>
                    {!!cards.length && cards?.map((card, idx) => <Card key={`card-${idx}`} card={card} selectedNumbers={selectedNumbers}/>)}
                </div>
            </BingoLayout>
            {(hasWin && annoucementShown) && <BingoAnnouncer onClick={() => setAnnoucementShown(false)}>You've got Bingo!</BingoAnnouncer>}
        </div>
    )
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { CardsForm } from './CardsForm'

const ResetButton = styled.button`
    padding: 8px 16px;
    margin: 20px 0 0 0; 
    border-radius: 6px;
    cursor: pointer;
    color: white;
    background: ${({ theme }) => theme.colors.danger};
    &:hover {
        background: ${({ theme }) => theme.colors.dangerHover};
    }
`

const NumberGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 30px);
    text-align: center;
    line-height: 30px;
    grid-gap: 5px;
    margin: 0 auto;
    * {
        cursor: pointer;
        border: 1px solid;
        &:hover {
            background: ${({ theme }) => theme.colors.bingoBackgroundHover};
        }
    }
`

const GridNumber = styled.div`
    cursor: pointer;
    border: 1px solid;
    color: ${({ isSelected, theme }) => isSelected ? theme.colors.white : theme.colors.text};
    background: ${({ isSelected, theme }) => isSelected ? theme.colors.primary : 'none'};
    user-select: none;
    &:hover {
        background: ${({ isSelected, theme }) => isSelected ? theme.colors.primaryAccent : theme.colors.bingoBackgroundHover};
    }
`

const CallNumber = ({ number, handleClick, isSelected }) => (
    <GridNumber onClick={() => handleClick(number)} isSelected={isSelected}>{number}</GridNumber>
)

const Tab = styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: ${({ active }) => active ? 'underline' : 'none'};
    margin-right: 10px;
`
const TabsContainer = styled.div`
    margin-bottom: 10px;
`

const TabSelector = ({ onTabSelect, activeTab}) => {
    return <TabsContainer>
        <Tab onClick={() => onTabSelect('board')} active={activeTab === 'board'}>Board</Tab>
        <Tab onClick={() => onTabSelect('cards')} active={activeTab === 'cards'}>Edit cards</Tab>
    </TabsContainer>
} 

export const NumberCaller = ({numbers, handleClick, selectedNumbers, resetGame}) => (
    <>
        <NumberGrid>
            {numbers.map((number, i) => (
                <CallNumber
                    key={`callnumber-${i}`}
                    number={number}
                    handleClick={handleClick}
                    isSelected={selectedNumbers.includes(number)}
                />
            ))}
        </NumberGrid>
        <ResetButton type="button" onClick={resetGame}>Reset game</ResetButton>
    </>
)


export const Tabs = ({numbers, handleClick, selectedNumbers, cards, setCards, resetGame}) => {
    const [activeTab, setActiveTab] = useState('board')
    
    const createDefaultValues = () => {
        const defaultValues = {}
        cards.forEach((card, idx) => {
            defaultValues[`title-${idx}`] = card.title
            defaultValues[`row-${idx}-0`] = card.rows[0].join()
            defaultValues[`row-${idx}-1`] = card.rows[1].join()
            defaultValues[`row-${idx}-2`] = card.rows[2].join()
        })
        return defaultValues
    }

    return <>
        <TabSelector activeTab={activeTab} onTabSelect={setActiveTab} />
        {activeTab === 'board' && <NumberCaller
                            handleClick={handleClick}
                            numbers={numbers}
                            selectedNumbers={selectedNumbers}
                            resetGame={resetGame}
                            />
        }
        {activeTab === 'cards' && <CardsForm cards={cards} setCards={setCards} defaultValues={createDefaultValues()}/>}
    </>
}